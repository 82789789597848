<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header style="border-bottom: 1px solid rgb(235, 237, 240)" title="活动管理">
          <template slot="extra">
            <a-button class="editable-add-btn" @click="handleAdd" size="small">
              新增
            </a-button>
          </template>
        </a-page-header>
      </div>
      <div ref="form">
        <a-form :form="form" layout="inline" @submit="handleSubmit" style="padding: 15px 0" ref="form">
          <a-form-item label="活动名称">
            <a-input v-decorator="[
              'title',
              { rules: [{ required: false }] },
            ]" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table :columns="columns" :data-source="activityList" :pagination="false" :row-key="(record) => record.id"
        :scroll="{ x: 1300, y: table_h }" @expand="expand" :expandIcon="expandIcon" :expandedRowKeys="expandedRowKeys"
        size="middle" ref="tableList">
        <!-- 
                  :scroll="{ x: 800, y: table_h }" -->
        <span slot="class" slot-scope="pingtai">
          <span v-if="pingtai == 'tb'">淘宝</span>
          <span v-if="pingtai == 'jd'">京东</span>
          <span v-if="pingtai == 'video_clip'">视频剪辑</span>
          <span v-if="pingtai == 'task'">任务</span>
        </span>
        <span slot="image_url" slot-scope="image_url">
          <img :src="image_url" alt="" />
        </span>
        <span slot="status" slot-scope="status">
          {{ status == 1 ? "正常" : "停止" }}
        </span>
        <span slot="settlement_status" slot-scope="settlement_status,nums">
          <a-switch :checked="settlement_status == 2" @change="changeSettlement(nums)"
            :disabled="settlement_status == 2" />
        </span>
        <span slot="btns" slot-scope="btns, num">
          <a-button class="editable-add-btn" v-for="btn in btns" :key="btn" @click="handel(btn, num)" size="small"
            style="margin: 5px">
            {{ btn }}
          </a-button>
        </span>

        <a-table slot="expandedRowRender" slot-scope="num" :columns="innerColumns" :data-source="num.data"
          :scroll="{ y: 400 }" :pagination="false" :row-key="(record) => record.id">
          <span slot="btns" slot-scope="btns, num">
            <a-button class="editable-add-btn" v-for="btn in btns" :key="btn" @click="expandedhandel(btn, num)"
              size="small" style="margin: 5px">
              {{ btn }}
            </a-button>
          </span>
        </a-table>
      </a-table>
      <div ref="pagination">
        <a-pagination v-if="pageshow" :default-current="currentPage" :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions" show-size-changer show-quick-jumper :total="total_num"
          :show-total="(total) => `共 ${total} 条`" @showSizeChange="onShowSizeChange" @change="onPageChange">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <a-modal v-model="visible" :title="modelTitle" :width="680" :confirm-loading="confirmLoading" @ok="handleOk"
        ok-text="确认" cancel-text="取消">
        <a-form :form="addform" @submit="handleSubmit">
          <a-form-item label="活动名称：">
            <a-input v-decorator="[
              'title',
              {
                rules: [
                  { required: true, message: '请输入活动名称!' },
                ],
              },
            ]" />
          </a-form-item>

          <a-form-item label="活动图片" extra="最多支持1张">
            <a-input v-decorator="[
              'image_url',
              {
                rules: [
                  { required: true, message: '请选择商品图片!' },
                ],
              },
            ]" type="hidden"></a-input>
            <a-upload name="file" list-type="picture" class="upload-list-inline" :fileList="fileList"
              :customRequest="changeImg" @change="getchangeImg" :remove="removeImg" :beforeUpload="handleBeforeUpload">
              <a-button :disabled="!uploadBtn">
                <a-icon type="upload" /> 上传图片
              </a-button>
            </a-upload>
          </a-form-item>

          <a-form-item label="活动时间">
            <a-range-picker show-time @change="onChange" v-decorator="[
              'indate',
              {
                rules: [
                  { required: true, message: '请选择活动时间!' },
                ],
              },
            ]">
            </a-range-picker>
          </a-form-item>
          <a-form-item label="活动落地页链接：">
            <a-input v-decorator="[
              'material_id',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入活动落地页链接!',
                  },
                ],
              },
            ]" />
          </a-form-item>

          <a-form-item label="活动规则">
            <a-input v-decorator="[
              'rule_text',
              {
                rules: [
                  { required: true, message: '请输入活动规则!' },
                ],
              },
            ]" placeholder="请输入活动规则。可填写多条规则，请用'；'分割" type="textarea" />
          </a-form-item>
          <a-form-item label="活动平台">
            <a-radio-group v-decorator="[
              'class',
              {
                rules: [
                  { required: true, message: '请选择活动平台!' },
                ],
              },
            ]" @change="changeClass"> 
              <a-radio value="jd"> 京东 </a-radio>
              <a-radio value="tb"> 淘宝 </a-radio>
              <a-radio value="video_clip"> 视频剪辑 </a-radio>
              <a-radio value="task"> 任务 </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="任务模板" v-if="classPlate=='task'">
            <a-select v-decorator="[
              'task_template_id',
              {
                rules: [
                  { required: true, message: '请选择任务模板!' },
                ],
              },
            ]">
              <a-select-option :value="item.template_id" v-for="item in task" :key="item.template_id"> {{item.name}} </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="活动状态">
            <a-radio-group v-decorator="[
              'status',
              {
                rules: [
                  { required: true, message: '请选择活动状态!' },
                ],
              },
            ]">
              <!-- initialValue: status_type, -->
              <a-radio :value="1"> 正常 </a-radio>
              <a-radio :value="2"> 停止 </a-radio>
            </a-radio-group>
          </a-form-item>

          <!-- <a-form-item label="结算状态">
                      <a-radio-group
                        v-decorator="[
                          'settlement_status',
                          {
                            rules: [
                              { required: true, message: '请选择结算状态!' },
                            ],
                          },
                        ]"
                      >
                        <a-radio :value="1"> 未结算 </a-radio>
                        <a-radio :value="2"> 可结算 </a-radio>
                      </a-radio-group>
                    </a-form-item> -->
          <a-form-item label="佣金描述：">
            <a-input v-decorator="[
              'brokerage_description',
              {
                rules: [
                  { required: true, message: '请输入佣金描述!' },
                ],
              },
            ]" type="textarea" />
          </a-form-item>
          <a-form-item label="口令规则">
            <a-input v-decorator="[
              'share_code_rule_text',
              {
                rules: [
                  { required: true, message: '请输入口令规则!' },
                ],
              },
            ]" placeholder="请输入口令规则。可填写多条规则，请用'；'分割" type="textarea" />
          </a-form-item>
          <a-form-item label="奖励规则">
            <a-input v-decorator="[
              'bonus_rule_text',
              {
                rules: [
                  { required: true, message: '请输入奖励规则!' },
                ],
              },
            ]" placeholder="请输入奖励规则。可填写多条规则，请用'；'分割" type="textarea" />
          </a-form-item>
          <a-form-item label="指南名称：">
            <a-input v-decorator="[
              'guide_title',
              {
                rules: [
                  { required: true, message: '请输入指南名称!' },
                ],
              },
            ]" />
          </a-form-item>
          <a-form-item label="指南富文本">
            <a-input v-decorator="[
              'guide_text',
              {
                rules: [
                  { required: true, message: '请输入指南内容!' },
                ],
              },
            ]" type="hidden"></a-input>
            <vue-ueditor-wrap :config="myConfig" v-model="message"></vue-ueditor-wrap>
          </a-form-item>
          <a-form-item label="平台验证：">
            <a-checkbox-group @change="onCheckboxChange" v-model="checked">
              <a-col :span="12" v-for="item in platform" :key="item.platform">
                <a-checkbox :value="item.platform">
                  {{ item.platform_name }}
                </a-checkbox>
                <span>
                  粉丝数
                  <a-input-number :precision="0" step="1" :min="0" v-decorator="[
                    'platform_verify.' + item.platform,
                    {
                      rules: [
                        {
                          required: false,
                          message: '请输入高度!',
                        },
                      ],
                    },
                  ]" /></span>
              </a-col>
            </a-checkbox-group>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model="visibleFActivity" :title="modelTitle" :confirm-loading="confirmLoading" @ok="handleFActivityOk"
        ok-text="确认" cancel-text="取消">
        <a-form :form="addFActivityform" @submit="handleSubmit">
          <a-form-item label="子活动标题：">
            <a-input v-decorator="[
              'title',
              {
                rules: [{ required: true, message: '子活动标题!' }],
              },
            ]" />
          </a-form-item>

          <a-form-item label="子活动描述：">
            <a-input v-decorator="[
              'description',
              {
                rules: [
                  { required: true, message: '请输入子活动描述!' },
                ],
              },
            ]" type="textarea" />
          </a-form-item>
          <a-form-item label="子活动落地页链接：">
            <a-input v-decorator="[
              'material_id',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入子活动落地页链接!',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "Car",
  data() {
    return {
      message: "",
      checked: [],
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      addFActivityform: this.$form.createForm(this, { name: "FActivityform" }),
      columns: [
        {
          title: "活动名称",
          key: "title",
          dataIndex: "title",
          align: "center",
          width: 200
        },
        {
          title: "活动平台",
          key: "class",
          dataIndex: "class",
          scopedSlots: { customRender: "class" },
          align: "center",
        },
        {
          title: "活动图片",
          key: "image_url",
          dataIndex: "image_url",
          align: "center",
          scopedSlots: { customRender: "image_url" },
        },
        {
          title: "佣金描述",
          key: "brokerage_description",
          dataIndex: "brokerage_description",
          align: "center",
        },
        {
          title: "活动领取人数",
          key: "participate_nu",
          dataIndex: "participate_nu",
          align: "center",
        },
        {
          title: "活动创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "活动结束时间",
          key: "end_time",
          dataIndex: "end_time",
          align: "center",
        },
        {
          title: "活动状态",
          key: "status",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "结算状态",
          key: "settlement_status",
          dataIndex: "settlement_status",
          scopedSlots: { customRender: "settlement_status" },
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
          width: 160
        },
      ],
      innerColumns: [
        {
          title: "子活动标题",
          key: "title",
          dataIndex: "title",
          align: "center",
        },
        {
          title: "子活动描述",
          key: "description",
          dataIndex: "description",
          align: "center",
        },
        {
          title: "子活动落地页链接",
          key: "material_id",
          dataIndex: "material_id",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      expandedRowKeys: [],
      // initialdate: null,
      end_time: "",
      visible: false,
      visibleFActivity: false,
      modelTitle: "",
      fileList: [],
      confirmLoading: false,
      uploadBtn: true,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      // table_h: "",
      activityList: [],
      canUpload: "",
      platform: [],
      myConfig: {
        UEDITOR_HOME_URL: "/UEditor/",
        autoHeightEnabled: false,
        enableAutoSave: false,
        initialFrameWidth: "100%",
        initialFrameHeight: "500",
      },
      classPlate:'',
      task:[]
    };
  },
  mounted() {
    // window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    // 日期切换
    // onChange(open) {
    //   console.log(open)
    //   this.end_time = open[0];
    // },
    changeClass(e){
      this.classPlate=e.target.value
      if(e.target.value=='task'){
        this.getTask()
      }
    },
    getTask(){
      var that=this
      that.axios
        .get("/admin/activity/activityTaskTemplateList")
        .then((res) => {
          if (that.$code(res)) {
            that.task = res.data.data;
            console.log(that.task)
          }
        });
    },
    onCheckboxChange(checkedValues) {
      this.checked = checkedValues;
    },
    onChange(value, dateString) {
      this.start_time = dateString[0];
      this.end_time = dateString[1];
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/activity/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var activityList = res.data.data;
            for (var i = 0; i < activityList.length; i++) {
              activityList[i].btns = ["创建子活动", "编辑", "删除"];
            }
            that.changeTableH();
            that.activityList = activityList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    // 操作（删除、编辑）
    handel(btn, num) {
      var that = this;
      var data;
      that.activity_id = num.id;

      if (btn == "编辑") {
        data = {
          activity_id: num.id,
        };

        that.axios.get("/app/platform/auth/platformType").then((res) => {
          that.platform = res.data.data;
          that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
          that.axios.get("/admin/activity/one", { params: data }).then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              that.uploadBtn = false;
              that.fileList = [
                {
                  uid: "-1",
                  name: "",
                  status: "done",
                  url: res.data.data.image_url,
                  thumbUrl: res.data.data.image_url,
                },
              ];
              that.visible = true;
              that.modelTitle = "编辑活动";
              that.classPlate=res.data.data.class
              if(that.classPlate=='task'){
                that.getTask()
              }
              that.end_time = res.data.data.end_time;
              that.start_time = res.data.data.start_time;
              var rule_text =
                res.data.data.rule_text.length > 0
                  ? JSON.parse(res.data.data.rule_text)
                  : res.data.data.rule_text;
              var str = "";
              if (rule_text.length > 0) {
                for (var i = 0; i < rule_text.length; i++) {
                  if (i != 0) {
                    str += "；";
                  }
                  str += rule_text[i];
                }
              }
              rule_text = str;
              var share_code_rule_text =
                res.data.data.share_code_rule_text.length > 0
                  ? JSON.parse(res.data.data.share_code_rule_text)
                  : res.data.data.share_code_rule_text;
              var str1 = "";
              if (share_code_rule_text.length > 0) {
                for (var j = 0; j < share_code_rule_text.length; j++) {
                  if (j != 0) {
                    str1 += "；";
                  }
                  str1 += share_code_rule_text[j];
                }
              }
              share_code_rule_text = str1;
              var bonus_rule_text =
                res.data.data.bonus_rule_text.length > 0
                  ? JSON.parse(res.data.data.bonus_rule_text)
                  : res.data.data.bonus_rule_text;
              var str2 = "";
              if (bonus_rule_text.length > 0) {
                for (var k = 0; k < bonus_rule_text.length; k++) {
                  if (k != 0) {
                    str2 += "；";
                  }
                  str2 += bonus_rule_text[k];
                }
              }
              bonus_rule_text = str2;
              that.message = res.data.data.guide_text;
              //粉丝数
              var jsonObj = res.data.data.platform_verify ? JSON.parse(res.data.data.platform_verify) : res.data.data.platform_verify;
              var jsonArr = [];
              var checked = [];
              for (var m = 0; m < jsonObj.length; m++) {
                jsonArr[m] = jsonObj[m];
              }

              var platform_verify = {};
              for (var a = 0; a < that.platform.length; a++) {
                platform_verify[that.platform[a].platform] = "";
                if (jsonArr.length > 0) {
                  for (var l = 0; l < jsonArr.length; l++) {
                    // if()
                    if (jsonArr[l].platform == that.platform[a].platform) {
                      checked.push(jsonArr[l].platform);
                      platform_verify[jsonArr[l].platform] = jsonArr[l].fans_count;
                    }
                  }
                }
              }
              that.checked = checked;
              that.$nextTick(() => {
                if(that.classPlate=='task'){
                  that.addform.setFieldsValue({
                    task_template_id:res.data.data.task_template_id
                  })
                }
                that.addform.setFieldsValue({
                  guide_title: res.data.data.guide_title,
                  guide_text: that.message,
                  title: res.data.data.title,
                  brokerage_description: res.data.data.brokerage_description,
                  image_url: res.data.data.image_url,
                  status: res.data.data.status,
                  platform_verify: platform_verify,
                  indate: [
                    res.data.data.start_time
                      ? moment(res.data.data.start_time, "YYYY-MM-DD hh-mm-ss")
                      : res.data.data.start_time,
                    res.data.data.end_time
                      ? moment(res.data.data.end_time, "YYYY-MM-DD hh-mm-ss")
                      : res.data.data.end_time,
                  ],
                  class: res.data.data.class,
                  rule_text: rule_text,
                  share_code_rule_text: share_code_rule_text,
                  bonus_rule_text: bonus_rule_text,
                  material_id: res.data.data.material_id,
                  // settlement_status: res.data.data.settlement_status,
                });
              });
            }
          });

        });
      }
      if (btn == "删除") {
        that.$confirm({
          title: "提示",
          content: "是否" + btn + "此活动",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
            data = {
              activity_id: num.id,
            };
            that.axios
              .get("/admin/activity/destroy", { params: data })
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(btn + "成功");
                  that.form.validateFields((error, values) => {
                    that.expandedRowKeys = [];
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      }
      if (btn == "创建子活动") {
        that.visibleFActivity = true;
        that.modelTitle = "创建子活动";
        that.activity_sub_id = "";
        that.uploadBtn = true;
        that.$nextTick(() => {
          that.addFActivityform.setFieldsValue({
            title: "",
            description: "",
            material_id: "",
          });
        });
      }
    },
    //结算
    changeSettlement(nums) {
      var that = this
      that.$confirm({
        title: "提示",
        content: "结算状态开启后不能撤回，并且此活动无法再给用户设置奖励金额。奖励金额每月25号给用户结算。",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
          that.axios
            .get("/admin/activity/modifySettlementStatus/" + nums.id)
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("操作成功");
                that.form.validateFields((error, values) => {
                  that.expandedRowKeys = [];
                  that.getSearchList(values);
                });
              }
            });
        },
      });
    },
    //上传图片
    changeImg(info) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios.post("/admin/upload/image", formData).then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var img = res.data.data.path_url;
          that.addform.setFieldsValue({
            image_url: img,
          });
          that.fileList = [
            {
              uid: info.file.uid,
              name: info.file.name,
              status: "done",
              url: "" + img,
              thumbUrl: "" + img,
            },
          ];
        }
      });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file) {
      if (file.type.indexOf("image") < 0) {
        this.$message.error("仅支持上传图片");
        this.canUpload = false;
        return false;
      } else {
        this.canUpload = true;
        return true;
      }
    },
    getchangeImg(info) {
      if (!this.canUpload) {
        info.fileList = [];
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.addform.setFieldsValue({
            image_url: "",
          });
        }
      }
    },
    //新增主活动
    handleAdd() {
      this.visible = true;
      this.modelTitle = "新增活动";
      this.classPlate='',
      this.activity_id = "";
      this.uploadBtn = true;
      this.fileList = [];
      var that = this;
      that.end_time = "";
      that.confirmLoading = false;
      that.axios.get("/app/platform/auth/platformType").then((res) => {
        that.platform = res.data.data;
      });
      that.end_time = "";
      that.start_time = "";
      that.message = "";
      that.checked = [];
      var platform_verify = {};
      for (var i = 0; i < that.platform.length; i++) {
        platform_verify[that.platform[i].platform] = "";
      }
      that.$nextTick(() => {
        that.addform.setFieldsValue({
          title: "",
          brokerage_description: "",
          image_url: "",
          status: "",
          material_id: "",
          rule_text: "",
          indate: "",
          class: "",
          share_code_rule_text: "",
          // settlement_status: "",
          bonus_rule_text: "",
          guide_text: "",
          guide_title: "",
          platform_verify: platform_verify,
        });
      });
    },
    //新增、编辑主活动提交表单
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.setFieldsValue({
        guide_text: that.message,
      });
      that.addform.validateFields((error, values) => {
        if (!error) {
          // 平台验证粉丝数
          var platform_verify = [];
          for (var j = 0; j < that.checked.length; j++) {
            if (!values.platform_verify[that.checked[j]]) {
              that.$message.error("请填写所选平台粉丝数");
              that.confirmLoading = false;
              return;
            } else {
              platform_verify.push({
                platform: that.checked[j],
                fans_count: values.platform_verify[that.checked[j]],
              });
            }
          }
          values.platform_verify = JSON.stringify(platform_verify);
          if (values.indate) {
            that.$delete(values, "indate");
          }
          values.start_time = that.start_time;
          values.end_time = that.end_time;
          // 活动规则
          var arr = values.rule_text.split("；");
          var rule_text = [];
          for (var i = 0; i < arr.length; i++) {
            if (arr[i]) {
              rule_text.push(arr[i]);
            }
          }
          values.rule_text = rule_text;
          // 奖励规则
          var arr_bonus = values.bonus_rule_text.split("；");
          var bonus_rule_text = [];
          for (var k = 0; k < arr_bonus.length; k++) {
            if (arr_bonus[k]) {
              bonus_rule_text.push(arr_bonus[k]);
            }
          }
          values.bonus_rule_text = bonus_rule_text;
          // 口令规则
          var arr_share = values.share_code_rule_text.split("；");
          var share_code_rule_text = [];
          for (var n = 0; n < arr_share.length; n++) {
            if (arr_share[n]) {
              share_code_rule_text.push(arr_share[n]);
            }
          }
          values.share_code_rule_text = share_code_rule_text;
          var data = values;
          if (!that.activity_id) {
            that.axios
              .post("/admin/activity/store", data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.expandedRowKeys = [];
                  that.getSearchList({});
                  that.visible = false;
                }
              });
          } else {
            data.activity_id = that.activity_id;
            that.axios
              .post("/admin/activity/update", data)
              .then((res) => {
                that.confirmLoading = false;
                //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
                if (that.$code(res)) {
                  that.expandedRowKeys = [];
                  that.getSearchList({});
                  that.visible = false;
                }
              });
          }
        } else {
          that.confirmLoading = false;
        }
      });
      // this.visible = false;
    },
    //新增、编辑子活动提交表单
    handleFActivityOk() {
      var that = this;
      that.confirmLoading = true;
      that.addFActivityform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          if (!that.activity_sub_id) {
            data.activity_id = that.activity_id;
            that.axios.post("/admin/activitySub/create", data).then((res) => {
              that.confirmLoading = false;
              //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
              if (that.$code(res)) {
                // that.getSearchList({});
                that.visibleFActivity = false;
                that.expand(true, { id: that.id });
              }
            });
          } else {
            data.activity_sub_id = that.activity_sub_id;
            that.axios.post("/admin/activitySub/update", data).then((res) => {
              that.confirmLoading = false;
              //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
              if (that.$code(res)) {
                // that.getSearchList({});
                that.visibleFActivity = false;
                that.expand(true, { id: that.id });
              }
            });
          }
        } else {
          that.confirmLoading = false;
        }
      });
    },
    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
    //子活动操作（删除、编辑）
    expandedhandel(btn, num) {
      var that = this;
      var data;
      that.activity_sub_id = num.id;
      if (btn == "编辑") {
        data = {
          activity_sub_id: num.id,
        };
        that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
        that.axios
          .get("/admin/activitySub/one", { params: data })
          .then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              that.uploadBtn = false;
              that.visibleFActivity = true;
              that.modelTitle = "编辑子活动";
              that.$nextTick(() => {
                that.addFActivityform.setFieldsValue({
                  title: res.data.data.title,
                  description: res.data.data.description,
                  material_id: res.data.data.material_id,
                });
              });
            }
          });
      }
      if (btn == "删除") {
        that.$confirm({
          title: "提示",
          content: "是否" + btn + "此子活动",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
            data = {
              activity_sub_id: num.id,
            };
            that.axios
              .get("/admin/activitySub/delete", { params: data })
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(btn + "成功");
                  // that.form.validateFields((error, values) => {
                  //   that.getSearchList(values);
                  // });

                  that.expand(true, { id: that.id });
                }
              });
          },
        });
      }
    },
    expandIcon(props) {
      if (props.expanded) {
        return (
          <a
            style="color: 'black',margin-right:0px"
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <a-icon type="caret-down" />{" "}
          </a>
        );
      } else {
        return (
          <a
            style="color: 'black' ,margin-right:0px"
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <a-icon type="caret-right" />
          </a>
        );
      }
    },
    expand(expanded, record) {
      var that = this;
      that.expandedRowKeys = [];
      if (expanded && record.id) {
        that.id = record.id;
        var data = {
          activity_id: record.id,
        };
        that.axios
          .get("/admin/activitySub/list", {
            params: data,
          })
          .then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              var activityList = that.activityList;
              for (var i = 0; i < activityList.length; i++) {
                if (activityList[i].id == record.id) {
                  activityList[i].data = res.data.data;
                  for (var j = 0; j < activityList[i].data.length; j++) {
                    activityList[i].data[j].btns = ["编辑", "删除"];
                  }
                  break;
                }
              }
              that.activityList = activityList;
              that.expandedRowKeys.push(record.id);
            }
          });
      }
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}

/* .ant-table-wrapper {
  flex: 1;
  overflow-y: scroll;
} */
/deep/.ant-checkbox-group>div {
  margin-bottom: 5px;
}

/deep/.ant-form-item-control-wrapp {
  height: 300px;
}
</style>